import React from "react";

function Error() {
  return (
    <div>
        <h1>Error</h1>
    </div>
  );
}

export default Error;
